import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const Banner7 = () => {
  const slidesData = [
    {
      id: 1,
      quote:
        "It’s so easy, just use the company login at the top of this page to register for an account!",
      rating: 5,
      authorId: 1,
    },
    {
      id: 2,
      quote:
        "Once registered, it’s easy to assign Users. Users can login on this page and begin using framework!",
      rating: 5,
      authorId: 2,
    },
    {
      id: 3,
      quote:
        "The Framework will take employee engagement to another level and challenge process maturity levels!",
      rating: 5,
      authorId: 3,
    },
    {
      id: 4,
      quote:
        "Need more Users, just email us and we will increase Users on your package.",
      rating: 3,
      authorId: 3,
    },
    {
      id: 5,
      quote:
        "Bring your team together to make improvements and change the culture.",
      rating: 5,
      authorId: 4,
    },
    {
      id: 6,
      quote: "Challenge your current standards and make them better.",
      rating: 5,
      authorId: 5,
    },
  ];

  return (
    <>
      <section className="relative md:py-24 py-16" id="users">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-6 text-center">
            <h3 className="font-semibold text-2xl leading-normal mb-4">
              How to Become a User
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              We’ve made it easy for you, just sign up and you will gain access
              to the 3S Framework to begin using today!
            </p>
          </div>
          {/*end grid*/}
          <div className="grid grid-cols-1 mt-6">
            <div className="tiny-three-item">
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                className="mySwiper"
              >
                {slidesData.map((slide, index) => (
                  <SwiperSlide key={slide.id}>
                    <div className="tiny-slide text-center">
                      <div className="cursor-e-resize">
                        <div className="content relative rounded shadow dark:shadow-gray-700 m-2 p-6 bg-white dark:bg-slate-900 before:content-[''] before:absolute before:start-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-e-white dark:before:border-e-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-s-transparent before:shadow-testi dark:before:shadow-gray-700 before:origin-top-left">
                          <i className="mdi mdi-format-quote-open mdi-48px text-teal-500" />
                          <p className="text-slate-400">{slide.quote}</p>
                          <ul className="list-none mb-0 text-amber-400 mt-3">
                            {Array(slide.rating)
                              .fill()
                              .map((_, index) => (
                                <li className="inline" key={index}>
                                  <i className="mdi mdi-star" />
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div className="text-center mt-5">
                          {/* Uncomment and update the src and alt properties as needed */}
                          {/* <img src={`/assets/images/team/${slide.authorId}.jpg`} className="size-14 rounded-full shadow-md dark:shadow-gray-700 mx-auto" alt="" /> */}
                          <h6 className="mt-2 font-bold text-teal-500">
                            {index + 1}
                          </h6>
                          {/* <span className="text-slate-400 text-sm">Manager</span> */}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          {/*end grid*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
    </>
  );
};

export default Banner7;
