import React, { useEffect, useState } from "react";
import { axiosInstance } from "../helper/http";
import { FaUser, FaUsers } from "react-icons/fa";

const Banner8 = () => {
  const [packages, setPackage] = useState([]);
  const [loader, setLoader] = useState(false);
  const [ischeck, setIscheck] = useState(false);
  const handleCheck = (event) => {
    const { checked } = event.target;
    setIscheck(checked);
  };
  const getPackage = () => {
    setLoader(true);
    axiosInstance
      .get("company/package/view")
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          setPackage(response?.data?.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    getPackage();
  }, []);
  return (
    <>
      {loader && <div className="loader" />}
      <section
        className="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800"
        id="pricing"
      >
        <div className="container relative">
          <div className="grid grid-cols-1 pb-6 text-center">
            <h3 className="font-semibold text-2xl leading-normal mb-4">
              Our Pricing
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              Find the plan that allows you to improve and transform your
              business-critical processes all in one place.
            </p>
          </div>
          <div class="flex justify-end items-center gap-10">
            <div className="flex items-center gap-4">
              <div className="text-md">Monthly</div>
              <div class="relative inline-block">
                <input
                  type="checkbox"
                  class="checkbox opacity-0 absolute"
                  id="range"
                  checked={ischeck}
                  onClick={handleCheck}
                />
                <label
                  class="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8"
                  for="range"
                >
                  <span class="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] size-7"></span>
                </label>
              </div>
              <div className="text-md">Yearly</div>
            </div>
          </div>{" "}
          {/*end grid*/}
          <div className="flex justify-center mt-6 gap-6 flex-wrap">
            {packages.length > 0 &&
              packages
                .filter(
                  (s) => s.package_type === (ischeck ? "Yearly" : "Monthly")
                )
                .map((e) => {
                  return (
                    <div
                      key={e.id}
                      className="w-full md:!w-[30%] group p-6 relative overflow-hidden shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 rounded-md h-fit"
                    >
                      <h6 className="font-semibold mb-5 text-2xl text-center">
                        {e?.plan}
                      </h6>
                      {/* <h6 className="font-semibold mb-5 text-xl">
                        {e?.package_name}
                      </h6> */}
                      <div className="flex mb-5">
                        <span className="text-lg font-medium">$</span>
                        <span className="price text-5xl h6 font-semibold mb-0">
                          {e?.package_amount}{" "}
                        </span>
                        <span className="text-lg font-medium self-end mb-1">
                          /{e?.package_type}
                        </span>
                      </div>
                      <ul className="list-none ">
                        <li className="mb-1 flex">
                          <i className="mdi mdi-check-circle-outline text-teal-500 text-[20px] align-middle me-2" />
                          <span className="flex items-center gap-3">
                            For {e?.package_users} Users
                            {Number(e?.package_users) > 1 ? (
                              <FaUsers className="dark:text-white  text-slate-400" />
                            ) : (
                              <FaUser className="dark:text-white  text-slate-400" />
                            )}
                          </span>
                        </li>
                      </ul>
                      <h6 className="font-normal text-gray-400 mb-5 text-lg">
                        {e?.description}
                      </h6>
                      <a
                        href="https://company.3sframework.org/"
                        className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500/5 hover:bg-teal-500 text-teal-500 hover:text-white w-full mt-5"
                      >
                        Buy Now
                      </a>
                      {e?.recommended === "true" && (
                        <span class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 absolute top-2 right-3">
                          Recommended
                        </span>
                      )}
                    </div>
                  );
                })}

            {/*end content*/}
          </div>
          {/*end grid*/}
        </div>
      </section>
    </>
  );
};

export default Banner8;
