import React from "react";

const Banner5 = () => {
  return (
    <>
      <section className="realtive md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 pb-6 text-center">
            <h3 className="font-semibold text-2xl leading-normal mb-4">
              A Performance-driven Framework
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              The 3S Framework will support & guide the business to World Class
              Innovation
            </p>
          </div>
          {/*end grid*/}
          <div className="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
            <div className="lg:col-span-4 md:col-span-5">
              <div className="sticky top-20">
                <ul
                  className="flex-column p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md"
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  <li role="presentation">
                    <button className="px-4 py-2 text-start text-base font-medium rounded-md w-full hover:text-teal-500 duration-500">
                      <span className="block">Step 1</span>
                      <span className="text-lg mt-2 block">
                        Strategic Decision
                      </span>
                      <span className="block mt-2">
                        Leadership makes the decision for change and business
                        transformation.
                      </span>
                    </button>
                  </li>
                  <li role="presentation">
                    <button className="px-4 py-2 text-start text-base font-medium rounded-md w-full mt-6 duration-500">
                      <span className="block">Step 2</span>
                      <span className="text-lg mt-2 block">
                        Project Execution
                      </span>
                      <span className="block mt-2">
                        Pick the plan that best fits your business needs.
                      </span>
                    </button>
                  </li>
                  <li role="presentation">
                    <button className="px-4 py-2 text-start text-base font-medium rounded-md w-full mt-6 duration-500">
                      <span className="block">Step 3</span>
                      <span className="text-lg mt-2 block">
                        Results &amp; Reporting
                      </span>
                      <span className="block mt-2">
                        Begin executing on the 3S Framework, track progress &
                        realize the results!
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:col-span-8 md:col-span-7">
              <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                <div>
                  <img
                    src="/assets/images/team.jpg"
                    className="shadow dark:shadow-gray-700 rounded-md"
                    alt=""
                  />
                  <div className="mt-6">
                    <h5 className="text-lg font-medium">
                      3S: Strategic/Success/ Sustained
                    </h5>
                    <p className="text-slate-400 mt-4">
                      By using our framework companies are challenged to meet
                      specific criteria with objective evidence as proof. The
                      leaders in the areas of focus will make improvements and
                      work as a team to ensure they meet the specific criteria
                      as detailed in Framework. Overall, the 3S Framework is
                      about systematic progression and enhancement of
                      organizational capabilities to achieve better performance,
                      quality, and customer satisfaction. It’s a strategic
                      approach to organizational growth and excellence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end grid*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
    </>
  );
};

export default Banner5;
