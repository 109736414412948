/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import { axiosInstance } from "../helper/http";
import { toast } from "react-toastify";
import Banner from "../components/banner";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowRight,
  FaArrowUp,
  FaDotCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Module = () => {
  const { id } = useSelector((state) => state?.auth?.user);

  const [currentlvl, setCurrentlvl] = useState(0);
  const [currentSubmodule, setCurrentSubmodule] = useState(null);
  console.log(currentSubmodule, "currentSubmodule");
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState([]);
  const [expandedModules, setExpandedModules] = useState(null);

  const toggleModule = (index) => {
    // if (expandedModules.includes(index)) {
    //   setExpandedModules(expandedModules.filter((item) => item !== index));
    // } else {
    //   setExpandedModules([...expandedModules, index]);
    // }
    setExpandedModules(index);
  };
  const getModule = () => {
    setLoader(true);
    axiosInstance.get(`user/module/view/${id}`).then((response) => {
      const { status, data, message } = response.data;
      if (status) {
        setData(data);
      } else {
        toast.error(message);
      }
    });
    setLoader(false);
  };
  const UpdateModule = (module_id, sub_module_id) => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("module_id", module_id);
    formdata.append("sub_module_id", sub_module_id);
    axiosInstance.post(`user/module/percentage`, formdata).then((response) => {
      const { status, message } = response.data;
      if (status) {
        getModule();
      } else {
        toast.error(message);
      }
    });
    setLoader(false);
  };

  useEffect(() => {
    getModule();
  }, []);
  return (
    <>
      {loader && <div className="loader" />}
      <Layout>
        <Banner />
        <section className="relative md:py-8">
          <div className="container relative">
            {data.length > 0 ? (
              data.map((module, index) => (
                <div
                  className="border-2 rounded-md hover:shadow-lg p-4 mb-4"
                  key={index}
                >
                  <div
                    className="flex justify-between items-center "
                    onClick={() => {
                      toggleModule(index);
                      setCurrentlvl(0);
                    }}
                  >
                    <div className="flex justify-between items-center gap-2">
                      <div>
                        {index + 1}: {module?.module_name}
                      </div>
                    </div>
                    <div>
                      {expandedModules === index ? (
                        <FaArrowUp className="text-[#0eb8a6] hover:text-black" />
                      ) : (
                        <FaArrowDown className="hover:text-[#0eb8a6]" />
                      )}
                    </div>
                  </div>

                  {expandedModules === index &&
                    module?.sub_module.map((subModule, subindex) => {
                      const percentage = subModule?.level?.find(
                        (lvl) => lvl?.per !== null
                      );
                      console.log(percentage);
                      return (
                        <>
                          <div className="container">
                            <div
                              className="flex justify-between items-center cursor-pointer mb-3"
                              key={subindex}
                            >
                              <div
                                className="flex w-full items-center gap-5"
                                onClick={() => setCurrentSubmodule(subindex)}
                              >
                                <FaDotCircle size={10} />
                                {subModule?.sub_module_name}
                              </div>
                              <div className="flex justify-end">
                                <CircularProgressbar
                                  value={percentage?.per?.percentage ?? 0}
                                  text={`${percentage?.per?.percentage ?? 0}%`}
                                  className="w-10 h-10 stroke-black dark:stroke-white"
                                  styles={buildStyles({
                                    backgroundColor: "#0eb8a6",
                                    textColor: "#0eb8a6",
                                    pathColor: "#0eb8a6",
                                    trailColor: "transparent",
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                          {currentSubmodule === subindex &&
                            subModule?.level.map(
                              (lvl, lvlIndex) =>
                                lvlIndex === currentlvl && (
                                  <div className="container ml-8 mb-3">
                                    <div
                                      className="flex justify-between items-center cursor-pointer"
                                      key={lvlIndex}
                                    >
                                      <div className="flex justify-between items-center gap-5">
                                        <FaDotCircle size={10} />
                                        {lvl?.level_name}
                                      </div>
                                    </div>
                                    <p className="text-gray-500 ml-7 mb-3">
                                      {lvl?.description}
                                    </p>
                                    <div className="flex justify-end gap-2">
                                      {currentlvl !== 0 && (
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            setCurrentlvl(currentlvl - 1)
                                          }
                                          className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white"
                                        >
                                          <FaArrowLeft />
                                          Previous
                                        </Link>
                                      )}
                                      {currentlvl !==
                                        subModule?.level.length - 1 && (
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setCurrentlvl(currentlvl + 1);
                                            UpdateModule(
                                              module?.id,
                                              subModule.id
                                            );
                                          }}
                                          className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white"
                                        >
                                          Next
                                          <FaArrowRight />
                                        </Link>
                                      )}
                                      {currentlvl ===
                                        subModule?.level.length - 1 && (
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setCurrentlvl(currentlvl + 1);
                                            UpdateModule(
                                              module?.id,
                                              subModule.id
                                            );
                                          }}
                                          className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white"
                                        >
                                          Done
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                )
                            )}
                        </>
                      );
                    })}
                </div>
              ))
            ) : (
              <p className="text-center">No Module Found</p>
            )}
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Module;
