import React from "react";
import { CgMail } from "react-icons/cg";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
      <div className="py-[30px] px-0 border-t border-slate-800">
        <div className="container relative text-center">
          <div className="grid lg:grid-cols-12 md:grid-cols-3 grid-cols-1 items-center">
            <div className="lg:col-span-3 md:text-start text-center">
              <Link to="/" className="text-[22px] focus:outline-none">
                <img
                  src="/assets/images/logo-light.png"
                  className="mx-auto md:me-auto md:ms-0"
                  alt=""
                  width={100}
                />
              </Link>
            </div>
            <div className="lg:col-span-5 text-center mt-6 md:mt-0">
              <p className="mb-0">
                © 3S FrameWork. Design with{" "}
                <i className="mdi mdi-heart text-red-600" /> by{" "}
                <a
                  href="https://hnhsofttechsolutions.com/"
                  className="text-reset"
                >
                  HnHSoftTechSolution .
                </a>
              </p>
            </div>
            <ul className="lg:col-span-4 list-none md:text-end text-center mt-6 md:mt-0">
              <li className="inline">
                <a
                  href={"https://www.linkedin.com/company/ci-innovations/"}
                  className="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-teal-500 dark:hover:bg-teal-500"
                >
                  <FaLinkedin
                    className="size-4 align-middle"
                    title="Linkedin"
                  />
                </a>
              </li>

              <li className="inline">
                <a
                  href={
                    "https://www.instagram.com/ciinnovations?igsh=MXNicHU5M2EzMWhodg%3D%3D&utm_source=qr"
                  }
                  className="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-teal-500 dark:hover:bg-teal-500"
                >
                  <FaInstagram
                    className="size-4 align-middle"
                    title="instagram"
                  />
                </a>
              </li>

              <li className="inline">
                <a
                  href="mailto:info@3sframework.org"
                  className="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-teal-500 dark:hover:bg-teal-500"
                >
                  <CgMail className="size-4 align-middle" title="email" />
                </a>
              </li>
            </ul>
            {/*end icon*/}
          </div>
          {/*end grid*/}
        </div>
        {/*end container*/}
      </div>
    </footer>
  );
};

export default Footer;
